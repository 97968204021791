import React from "react";
import loadable from '@loadable/component'

const ImageWithTextContent = loadable(() =>  import("./image-with-text-content"));
const ImageOffsetWithTextContent = loadable(() =>  import("./image-offset-with-text-content"));

const Start = ({ module }) => {
    const { featuredContentWithImage } = module;
    const { display } = featuredContentWithImage;

    switch(display) {
        case "image-with-text-content":
            return <ImageWithTextContent module={featuredContentWithImage} />;
        case "image-offset-with-text-content":
            return <ImageOffsetWithTextContent module={featuredContentWithImage} />;
        default:
            return null;
    }
};

export default Start;